<template>
  <div>
    <a-form ref="formRef" :model="form" :rules="rules" :labelCol="{ flex: '120px' }" scrollToFirstError>
      <a-form-item>
        <span style="color:#344563;font-weight:700">线下支付</span>
      </a-form-item>
      <!-- <a-form-item :wrapperCol="{ flex: 'auto' }" label="付款总金额">
        <a-typography-text type="danger">{{ amount }} 元 </a-typography-text>
        <template #extra>
          <a-typography-text type="secondary">{{ amountUppercase(amount) }}</a-typography-text>
        </template>
      </a-form-item> -->
      <a-form-item label="收款方式" :wrapperCol="{ flex: 'auto' }" name="mod">
        <a-select v-model:value="form.mod">
          <a-select-option value="微信">微信</a-select-option>
          <a-select-option value="支付宝">支付宝</a-select-option>
          <a-select-option value="银行卡">银行卡</a-select-option>
        </a-select>
      </a-form-item>
      <!-- <a-form-item v-if="form.mod == '银行卡'" label="选择承运商抬头" :wrapperCol="{ flex: 'auto' }">
        <a-button type="primary" :loading="loading" @click="instanceStart">选择</a-button>
      </a-form-item> -->
      <a-form-item label="账号" :wrapperCol="{ flex: 'auto' }">
        <a-input v-model:value="form.accountNum" />
      </a-form-item>
      <a-form-item label="户名" :wrapperCol="{ flex: 'auto' }">
        <a-input v-model:value="form.accountName" />
      </a-form-item>
      <a-form-item label="开户行" :wrapperCol="{ flex: 'auto' }">
        <a-input v-model:value="form.accountBank" />
      </a-form-item>
      <a-form-item label="收款码" :wrapperCol="{ flex: 'auto' }">
        <a-upload v-model:file-list="imgFileList" accept="image/*" list-type="picture" @preview="handlePreview"
          :remove="handleRemove" :before-upload="beforeUpload">
          <a-button :disabled="imgFileList.length > 0">
            <CameraOutlined />
            图片
          </a-button>
        </a-upload>
      </a-form-item>
      <a-form-item :wrapperCol="{ flex: 'auto' }" label="备注">
        <a-textarea v-model:value="form.remark" />
      </a-form-item>
      <!-- <a-form-item :wrapperCol="{ flex: 'auto' }" label="上传其他图片">
        <a-upload list-type="picture" accept="image/*" v-model:file-list="otherimgList" @preview="handleOtherPreview"
          :before-upload="beforeOtherUpload">
          <div class="clearfix" v-if="otherimgList.length < 8">
            <plus-outlined />
            <div class="ant-upload-text">上传</div>
          </div>
        </a-upload>
      </a-form-item> -->
      <a-form-item>
        <span style="color:#344563;font-weight:700">线上支付</span>
      </a-form-item>
      <a-form-item label="收款方类型">
        <a-select v-model:value="form.objectType" @change="typeChange">
          <a-select-option :value="2">承运商</a-select-option>
          <a-select-option :value="1">客户</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item v-show="form.objectType" :label="nameTitle">
        <a-select placeholder="搜索并选择" v-model:value="clientName" @search="searchClient" labelInValue :filterOption="false"
          show-search>
          <template v-if="fetching" #notFoundContent>
            <a-spin size="small" />
          </template>
          <a-select-option v-for="item in clientList" :key="item.id" @click="carrierClient(item)">
            {{ item.name + '-' + item.mobile }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item v-show="form.objectType" :label="vipTitle">
        <a-select placeholder="选择会员账号" v-model:value="vipName">
          <a-select-option v-for="item in VIPaccountList" :key="item.id" @click="chooseVIP(item)">
            {{ item.memberAlias + '-' + item.phone + '-' + item.creator }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item v-show="form.objectType" label="银行卡号">
        <a-select placeholder="选择卡号" v-model:value="bankNo">
          <a-select-option v-for="item in bankNoList" :key="item.bankCard" @click="chooseBankCarNoChange(item)">
            {{ item.bankName + '-' + item.bankTrueCard }}
          </a-select-option>
        </a-select>
      </a-form-item>
    </a-form>
  </div>
  <a-modal :visible="previewOtherVisible" :footer="null" @cancel="handleOtherCancel">
    <img alt="预览图片" style="width: 100%" :src="previewOtherImage" />
  </a-modal>
  <div :style="{
    position: 'absolute',
    right: 0,
    bottom: 0,
    width: '100%',
    borderTop: '1px solid #e9e9e9',
    padding: '10px 16px',
    background: '#fff',
    textAlign: 'right',
    zIndex: 1,
  }">
    <a-button type="primary" :loading="loading" @click="instanceStart">提交</a-button>
  </div>
  <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
    <img alt="预览图片" style="width: 100%" :src="previewImage" />
  </a-modal>
</template>
<script>
import { start } from '@/api/system/bpm/instance'
import { fileUpload as uploadBpm } from '@/api/marketing/modList'
import { reactive, ref, toRefs, onMounted } from 'vue'
import { message } from 'ant-design-vue'
import { getMemberRel, queryBankCard } from '@/api/carrier/cloudFlash'
import { fuzzyAll } from '@/api/crm/carrer/carrier'
import { CameraOutlined, PlusOutlined } from '@ant-design/icons-vue'
import { amountUppercase } from '@/utils/util'

function getBase64 (img, callback) {
  const reader = new FileReader()
  reader.addEventListener('load', () => callback(reader.result))
  reader.readAsDataURL(img)
}
export default {
  props: {
    flowType: { type: Number },
    amount: { type: Number, default: 0 },
    settlementInfo: { type: Object, default: null }
  },
  setup (props, { emit }) {
    const formRef = ref()
    const state = reactive({
      previewVisible: false,
      clientName: null,
      vipName: null,
      timeOutId: null,
      previewImage: '',
      nameTitle: '',
      vipTitle: '',
      bankNo: '',
      bankCardId: null,
      fetching: false,
      loading: false,
      bankNoList: [],
      clientList: [],
      VIPaccountList: [],
      imgFileList: [],
      previewOtherImage: '',
      previewOtherVisible: false,
      otherimgList: [],
      fileIds: [],
      form: {
        amt: props.amount,
        mod: null,
        objectType: null,
        bizUserId: null,
        accountNum: '',
        accountName: '',
        accountBank: '',
        fileId: '',
        remark: ''
      },
      mirrorForm: {},
      rules: {
        mod: { required: true, message: '请选择收款方式', trigger: 'change' },
        objectType: { required: true, message: '请选择收款方类型', trigger: 'blur', type: 'number' },
        objectId: { required: true, message: '请选择', trigger: 'blur' },
        bizUserId: { required: true, message: '请选择会员账号', trigger: 'blur' }
      }
    })
    onMounted(() => {
      state.mirrorForm = JSON.stringify(state.form)
    })
    const handleRemove = file => {
      const index = state.imgFileList.indexOf(file)
      const newFileList = state.imgFileList.slice()
      newFileList.splice(index, 1)
      state.imgFileList = newFileList
    }
    const beforeUpload = file => {
      state.imgFileList = [...state.imgFileList, file]
      return false
    }
    const beforeOtherUpload = file => {
      state.otherimgList = [...state.otherimgList, file]
      return false
    }
    const fileOnload = (allImgFile, i) => {
      const formData = new FormData()
      formData.append('file', allImgFile[i].originFileObj)
      uploadBpm(formData).then(res => {
        if (res.code === 10000) {
          if (i === 0) {
            state.form.paymentfileId = res.data.id
            state.form.payeeFileId = res.data.id
          } else {
            state.fileIds.push(res.data.id)
          }
          formData.delete('file')
          if (i + 1 >= allImgFile.length) {
            startBpm()
          }
          if (allImgFile.length > 1) {
            if (i + 1 >= allImgFile.length) {
              return
            }
            fileOnload(allImgFile, i + 1)
          }
        }
      }).catch(err => {
        console.log(err)
        state.loading = false
      })
    }
    const typeChange = (e) => {
      state.form.objectType = e
      state.form.objectId = ''
      state.clientName = null
      state.vipName = null
      state.clientList = []
      state.VIPaccountList = []
      if (e === 1) {
        state.nameTitle = '选择客户'
        state.vipTitle = '客户会员账号'
      } else {
        state.nameTitle = '选择承运商'
        state.vipTitle = '承运商会员账号'
      }
    }
    const searchClient = (value) => {
      if (value === '' || value === null) clearTimeout(state.timeOutId), state.fetching = false
      if (value.length > 1) {
        state.fetching = true
        if (state.timeOutId !== null) {
          clearTimeout(state.timeOutId)
        }
        state.timeOutId = setTimeout(() => {
          fuzzyAll({ name: value, type: state.form.objectType }).then(res => {
            if (res.code === 10000) { state.clientList = res.data }
            else state.clientList = []
          }).finally(() => { state.fetching = false })
        }, 1000)
      } else {
        state.clientList = []
      }
    }
    const carrierClient = item => {
      state.form.objectId = item.id
      state.form.objectName = item.name
      getMemberRel({ objectId: state.form.objectId }).then(res => {
        if (res.code === 10000) {
          state.VIPaccountList = res.data
        }
      })
    }
    const chooseVIP = item => {
      state.form.memberAlias = item.memberAlias
      state.form.bizUserId = item.bizUserId
      queryBankCard({
        bizUserId: item.bizUserId
      }).then(res => {
        if (res.code === 10000) {
          state.bankNoList = res.data
        }
      })
    }
    const instanceStart = () => {
      formRef.value.validate()
        .then(() => {
          const allImgFile = state.imgFileList.concat(state.otherimgList)
          state.loading = true
          if (allImgFile.length > 0) {
            fileOnload(allImgFile, 0)
          } else {
            startBpm()
          }
        })
    }
    const startBpm = () => {
      start({
        bizParams: {
          applyAccounts: [state.form],
          ...props.settlementInfo,
          remark: state.form.remark
        },
        bankCardId: state.bankCardId,
        bizUserId: state.form.bizUserId === '' ? null : state.form.bizUserId,
        objectId: state.form.objectId,
        objectType: state.form.objectType,
        objectName: state.form.objectName,
        memberAlias: state.form.memberAlias,
        fileIds: state.fileIds,
        flowId: '',
        // 用流程类型代替 id
        type: props.flowType
      }).then(res => {
        if (res.code === 10000) {
          message.success(res.msg)
          emit('ok', res.data)
          state.fileIds = []
          state.bankNo = ''
          state.bankCardId = null
        } else {
          message.error(res.msg)
        }
      }).catch(err => {
        console.log(err)
      }).finally(() => {
        state.loading = false
      })
    }
    const handleOtherPreview = async file => {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj, base64Url => {
          state.previewOtherImage = base64Url
        })
      }
      state.previewOtherVisible = true
    }
    const handleOtherCancel = () => {
      state.previewOtherVisible = false
    }
    const handlePreview = async file => {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj, base64Url => {
          state.previewImage = base64Url
        })
      }

      state.previewVisible = true
    }
    const handleCancel = () => {
      state.previewVisible = false
    }
    const clearData = () => {
      state.form = JSON.parse(state.mirrorForm)
    }
    const confirmData = () => {
      formRef.value.validate().then(() => {
        context.emit('supplement', state.form)
        console.log('确认资料补充', state.form);

      })
    }
    const chooseBankCarNoChange = item => {
      state.bankCardId = item.id
      // let index = null
      // if (state.applyAccounts.forms[0].project == 3) index = 0
      // else index = 1
      // state.applyAccounts.forms[index].mod = '银行卡'
      // state.applyAccounts.forms[index].accountBank = item.bankName
      // state.applyAccounts.forms[index].name = item.bankName
      // state.applyAccounts.forms[index].accountNum = item.bankTrueCard
    }
    return {
      formRef,
      ...toRefs(state),
      handleRemove,
      beforeUpload,
      instanceStart,
      amountUppercase,
      handlePreview,
      handleCancel,
      fileOnload,
      typeChange,
      chooseVIP,
      confirmData,
      searchClient,
      carrierClient,
      beforeOtherUpload,
      handleOtherCancel,
      handleOtherPreview,
      chooseBankCarNoChange
    }
  },
  components: {
    CameraOutlined,
    PlusOutlined
  }
}
</script>
<style lang="less" scoped>
.clearfix {
  width: 128px;
  height: 128px;
  border: 1px dashed gray;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
</style>
